import React from 'react'  

export function MainContent( { children }) {
    return (

        <>  
            { children }
        
        </> 
    )
} 