import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Link } from "react-router-dom"; 
import { useLocation } from "react-router"; 
import { checkIsActive } from '../../../_metronic/_helpers';

export default function ProfileTabs() {
    const location = useLocation(); 
 
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? `active` : "";
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12 profile_tabs_card">
                    <Nav fill variant="tabs" defaultActiveKey="address">
                        <Nav.Item>
                            <Link to={`/my-profile/personal-info`} className={`nav-link ${getMenuItemActive("/my-profile/personal-info")}`}> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g clipPath="url(#clip0_1203_10739)">
                                            <path d="M6.21491 4.97761C6.09706 4.97761 6.00063 5.07404 6.00063 5.1919V6.47761C6.00063 6.59547 6.09706 6.6919 6.21491 6.6919H16.5006C16.6185 6.6919 16.7149 6.59547 16.7149 6.47761V5.1919C16.7149 5.07404 16.6185 4.97761 16.5006 4.97761H6.21491ZM11.1435 8.83475H6.21491C6.09706 8.83475 6.00063 8.93118 6.00063 9.04904V10.3348C6.00063 10.4526 6.09706 10.549 6.21491 10.549H11.1435C11.2613 10.549 11.3578 10.4526 11.3578 10.3348V9.04904C11.3578 8.93118 11.2613 8.83475 11.1435 8.83475ZM9.85777 21.1026H3.85777V2.24547H18.8578V10.8169C18.8578 10.9348 18.9542 11.0312 19.0721 11.0312H20.5721C20.6899 11.0312 20.7863 10.9348 20.7863 10.8169V1.17404C20.7863 0.69993 20.4033 0.316895 19.9292 0.316895H2.78634C2.31223 0.316895 1.9292 0.69993 1.9292 1.17404V22.174C1.9292 22.6481 2.31223 23.0312 2.78634 23.0312H9.85777C9.97563 23.0312 10.0721 22.9348 10.0721 22.8169V21.3169C10.0721 21.199 9.97563 21.1026 9.85777 21.1026ZM21.6435 18.7455H17.7863V17.7651C19.0265 17.3955 19.9292 16.249 19.9292 14.8883C19.9292 13.2303 18.5872 11.8883 16.9292 11.8883C15.2712 11.8883 13.9292 13.2303 13.9292 14.8883C13.9292 16.2464 14.8319 17.3955 16.0721 17.7651V18.7455H12.2149C11.9792 18.7455 11.7863 18.9383 11.7863 19.174V23.2455C11.7863 23.4812 11.9792 23.674 12.2149 23.674H21.6435C21.8792 23.674 22.0721 23.4812 22.0721 23.2455V19.174C22.0721 18.9383 21.8792 18.7455 21.6435 18.7455ZM15.5899 14.8883C15.5899 14.149 16.1899 13.549 16.9292 13.549C17.6685 13.549 18.2685 14.149 18.2685 14.8883C18.2685 15.6276 17.6685 16.2276 16.9292 16.2276C16.1899 16.2276 15.5899 15.6276 15.5899 14.8883ZM20.4113 22.0133H13.4471V20.4062H20.4113V22.0133Z" fill="#1890FF" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1203_10739">
                                                <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Personal <br /> Information 
                            </Link> 
                        </Nav.Item>

                        {/* <Nav.Item>
                            <Link to={`/my-profile/address`} className={`nav-link ${getMenuItemActive("/my-profile/address")}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M12.669 4.04565L3.99717 12.7105H6.24717V20.3441H9.8542V15.0003C9.8542 14.4824 10.2737 14.0628 10.7917 14.0628H14.5417C15.0597 14.0628 15.4792 14.4824 15.4792 15.0003V20.3441H19.0862V12.7105H21.3386L13.2104 4.58706L12.669 4.04565ZM22.8526 13.9574C22.712 14.098 22.5456 14.2105 22.3651 14.2832C22.5456 14.2082 22.712 14.098 22.8526 13.9574ZM2.06357 13.1441C2.18076 13.8542 2.79951 14.398 3.54248 14.398H3.56357C2.81592 14.398 2.18311 13.8589 2.06357 13.1441ZM2.04248 12.898V12.8535V12.898ZM2.04482 12.8371C2.04717 12.7644 2.05654 12.6941 2.06826 12.6238C2.0542 12.6917 2.04717 12.7644 2.04482 12.8371Z" fill="#E6F7FF" />
                                    <path d="M22.9559 11.9534C22.9559 11.9511 22.9535 11.9511 22.9535 11.9487L22.9113 11.8995C22.909 11.8972 22.9066 11.8925 22.902 11.8901C22.8855 11.8714 22.8668 11.8526 22.8504 11.8339L13.7941 2.78468L13.1871 2.17765C13.0488 2.04025 12.8618 1.96313 12.6668 1.96313C12.4718 1.96313 12.2848 2.04025 12.1465 2.17765L2.4832 11.8362C2.30863 12.0099 2.17979 12.2241 2.1082 12.4597L2.09414 12.5089L2.08711 12.5347L2.08008 12.5628C2.07539 12.5792 2.07305 12.5956 2.0707 12.612C2.0707 12.6144 2.0707 12.6191 2.06836 12.6214C2.0543 12.6917 2.04727 12.762 2.04492 12.8347V12.912C2.04492 12.9237 2.04492 12.9355 2.04727 12.9472C2.04727 12.9589 2.04727 12.9683 2.04961 12.98C2.04961 12.9917 2.05195 13.0034 2.05195 13.0151C2.05195 13.0292 2.0543 13.0433 2.05664 13.0573C2.05664 13.0644 2.05898 13.0714 2.05898 13.0784L2.06602 13.137V13.1394C2.18555 13.8542 2.8207 14.3933 3.56602 14.3933H4.56211V22.0316H20.7738V14.398H21.791C21.9926 14.398 22.1871 14.3581 22.3652 14.2831C22.5434 14.2081 22.7098 14.098 22.8527 13.9573C22.9924 13.8181 23.103 13.6526 23.1782 13.4704C23.2535 13.2881 23.2918 13.0928 23.291 12.8956C23.291 12.5511 23.1738 12.2206 22.9559 11.9534ZM13.9793 20.3441H11.3543V15.5628H13.9793V20.3441ZM19.0863 12.7105V20.3441H15.4793V15.0003C15.4793 14.4823 15.0598 14.0628 14.5418 14.0628H10.7918C10.2738 14.0628 9.8543 14.4823 9.8543 15.0003V20.3441H6.24727V12.7105H3.99727L12.6691 4.04562L13.2105 4.58702L21.3387 12.7105H19.0863Z" fill="#1890FF" />
                                </svg>
                                Address
                            </Link>
                        </Nav.Item> */}

                        <Nav.Item>
                            <Link to={`/my-profile/educational-info`} className={`nav-link ${getMenuItemActive("/my-profile/educational-info")}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M19.8333 1.50024H4.83325C4.41841 1.50024 4.08325 1.8354 4.08325 2.25024V21.7502C4.08325 22.1651 4.41841 22.5002 4.83325 22.5002H19.8333C20.2481 22.5002 20.5833 22.1651 20.5833 21.7502V2.25024C20.5833 1.8354 20.2481 1.50024 19.8333 1.50024ZM13.7395 3.18774H15.9895V8.10728L14.8997 7.31274L13.7395 8.14243V3.18774ZM5.77075 20.8127V3.18774H12.3333V10.1463C12.3333 10.2237 12.3567 10.301 12.4036 10.3643C12.432 10.4045 12.4682 10.4387 12.5099 10.4649C12.5517 10.491 12.5982 10.5087 12.6468 10.5167C12.6954 10.5248 12.7451 10.5231 12.7931 10.5119C12.8411 10.5006 12.8863 10.4799 12.9262 10.451L14.8903 9.04712L16.7981 10.4393C16.8614 10.4862 16.9387 10.512 17.0184 10.512C17.2247 10.512 17.3934 10.3432 17.3934 10.137V3.18774H18.8934V20.8127H5.77075Z" fill="#1890FF" />
                                    <path d="M15.9895 8.10728V3.18774H13.7395V8.14243L14.8997 7.31274L15.9895 8.10728Z" fill="#E6F7FF" />
                                    <path d="M17.3934 3.18774V10.137C17.3934 10.3432 17.2247 10.512 17.0184 10.512C16.9387 10.512 16.8614 10.4862 16.7981 10.4393L14.8903 9.04712L12.9262 10.451C12.8863 10.4799 12.8411 10.5006 12.7931 10.5119C12.7451 10.5231 12.6954 10.5248 12.6468 10.5167C12.5982 10.5087 12.5517 10.491 12.5099 10.4649C12.4682 10.4387 12.432 10.4045 12.4036 10.3643C12.3567 10.301 12.3333 10.2237 12.3333 10.1463V3.18774H5.77075V20.8127H18.8934V3.18774H17.3934Z" fill="#E6F7FF" />
                                </svg>
                                Educational  <br /> Information
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to={`/my-profile/professional-experience`} className={`nav-link ${getMenuItemActive("/my-profile/professional-experience")}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M11.4551 12.3284C11.4551 12.6516 11.5828 12.9616 11.8101 13.1902C12.0373 13.4187 12.3455 13.5471 12.6669 13.5471C12.9882 13.5471 13.2964 13.4187 13.5237 13.1902C13.7509 12.9616 13.8786 12.6516 13.8786 12.3284C13.8786 12.1683 13.8472 12.0098 13.7863 11.862C13.7255 11.7141 13.6362 11.5798 13.5237 11.4666C13.4112 11.3534 13.2776 11.2636 13.1306 11.2024C12.9836 11.1411 12.826 11.1096 12.6669 11.1096C12.5077 11.1096 12.3502 11.1411 12.2032 11.2024C12.0561 11.2636 11.9226 11.3534 11.8101 11.4666C11.6975 11.5798 11.6083 11.7141 11.5474 11.862C11.4865 12.0098 11.4551 12.1683 11.4551 12.3284Z" fill="#E6F7FF" />
                                    <path d="M18.6667 8.25024C18.6667 8.35337 18.5824 8.43774 18.4792 8.43774H17.1667C17.0636 8.43774 16.9792 8.35337 16.9792 8.25024V6.93774H13.5105V8.25024C13.5105 8.35337 13.4261 8.43774 13.323 8.43774H12.0105C11.9074 8.43774 11.823 8.35337 11.823 8.25024V6.93774H8.35425V8.25024C8.35425 8.35337 8.26987 8.43774 8.16675 8.43774H6.85425C6.75112 8.43774 6.66675 8.35337 6.66675 8.25024V6.93774H3.85425V18.5627H21.4792V6.93774H18.6667V8.25024ZM16.1589 17.2502H15.1324C15.0339 17.2502 14.9542 17.1729 14.9472 17.0745C14.8582 15.8909 13.8691 14.9534 12.6691 14.9534C11.4691 14.9534 10.48 15.8885 10.391 17.0745C10.3839 17.1729 10.3042 17.2502 10.2058 17.2502H9.1769C9.15147 17.2503 9.1263 17.2451 9.10292 17.2351C9.07954 17.2251 9.05843 17.2105 9.04089 17.1921C9.02335 17.1736 9.00975 17.1518 9.00089 17.128C8.99204 17.1042 8.98813 17.0788 8.9894 17.0534C9.05503 15.8041 9.73706 14.719 10.7378 14.0979C10.3136 13.6292 10.0558 13.0104 10.0558 12.3284C10.0558 10.8776 11.2253 9.70337 12.6667 9.70337C14.1082 9.70337 15.2777 10.8776 15.2777 12.3284C15.2777 13.0104 15.0199 13.6315 14.5957 14.0979C15.5964 14.7167 16.2808 15.8041 16.3464 17.0534C16.3477 17.0788 16.3438 17.1042 16.3349 17.128C16.3261 17.1518 16.3125 17.1736 16.2949 17.1921C16.2774 17.2105 16.2563 17.2251 16.2329 17.2351C16.2095 17.2451 16.1844 17.2503 16.1589 17.2502Z" fill="#E6F7FF" />
                                    <path d="M14.5957 14.0979C15.036 13.6139 15.2793 12.9827 15.2778 12.3284C15.2778 10.8776 14.1082 9.70337 12.6668 9.70337C11.2254 9.70337 10.0559 10.8776 10.0559 12.3284C10.0559 13.0104 10.3137 13.6292 10.7379 14.0979C10.2296 14.4129 9.80514 14.8462 9.50067 15.3609C9.19621 15.8755 9.02082 16.4562 8.9895 17.0534C8.98823 17.0788 8.99214 17.1042 9.00099 17.128C9.00984 17.1518 9.02345 17.1736 9.04099 17.1921C9.05853 17.2105 9.07963 17.2251 9.10302 17.2351C9.1264 17.2451 9.15157 17.2503 9.177 17.2502H10.2059C10.3043 17.2502 10.384 17.1729 10.3911 17.0745C10.4801 15.8885 11.4692 14.9534 12.6692 14.9534C13.8692 14.9534 14.8582 15.8909 14.9473 17.0745C14.9543 17.1729 15.034 17.2502 15.1325 17.2502H16.159C16.1844 17.2503 16.2096 17.2451 16.233 17.2351C16.2564 17.2251 16.2775 17.2105 16.295 17.1921C16.3126 17.1736 16.3262 17.1518 16.335 17.128C16.3439 17.1042 16.3478 17.0788 16.3465 17.0534C16.2809 15.8041 15.5965 14.7167 14.5957 14.0979ZM12.6668 13.5471C11.9989 13.5471 11.4551 13.001 11.4551 12.3284C11.4551 11.6557 11.9989 11.1096 12.6668 11.1096C13.3348 11.1096 13.8786 11.6557 13.8786 12.3284C13.8786 13.001 13.3348 13.5471 12.6668 13.5471Z" fill="#1890FF" />
                                    <path d="M22.4167 5.25024H18.6667V3.93774C18.6667 3.83462 18.5824 3.75024 18.4792 3.75024H17.1667C17.0636 3.75024 16.9792 3.83462 16.9792 3.93774V5.25024H13.5105V3.93774C13.5105 3.83462 13.4261 3.75024 13.323 3.75024H12.0105C11.9074 3.75024 11.823 3.83462 11.823 3.93774V5.25024H8.35425V3.93774C8.35425 3.83462 8.26987 3.75024 8.16675 3.75024H6.85425C6.75112 3.75024 6.66675 3.83462 6.66675 3.93774V5.25024H2.91675C2.5019 5.25024 2.16675 5.5854 2.16675 6.00024V19.5002C2.16675 19.9151 2.5019 20.2502 2.91675 20.2502H22.4167C22.8316 20.2502 23.1667 19.9151 23.1667 19.5002V6.00024C23.1667 5.5854 22.8316 5.25024 22.4167 5.25024ZM21.4792 18.5627H3.85425V6.93774H6.66675V8.25024C6.66675 8.35337 6.75112 8.43774 6.85425 8.43774H8.16675C8.26987 8.43774 8.35425 8.35337 8.35425 8.25024V6.93774H11.823V8.25024C11.823 8.35337 11.9074 8.43774 12.0105 8.43774H13.323C13.4261 8.43774 13.5105 8.35337 13.5105 8.25024V6.93774H16.9792V8.25024C16.9792 8.35337 17.0636 8.43774 17.1667 8.43774H18.4792C18.5824 8.43774 18.6667 8.35337 18.6667 8.25024V6.93774H21.4792V18.5627Z" fill="#1890FF" />
                                </svg>
                                Experience
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to={`/my-profile/training-specialization`} className={`nav-link ${getMenuItemActive("/my-profile/training-specialization")}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5.01562 5.31387V15.3357L12 20.7779L18.9844 15.3357V5.31387L12 2.93262L5.01562 5.31387ZM14.8312 7.68809H16.125C16.2773 7.68809 16.3664 7.86152 16.2773 7.98574L11.2945 14.8459C11.2595 14.8938 11.2137 14.9328 11.1607 14.9597C11.1078 14.9866 11.0492 15.0006 10.9898 15.0006C10.9305 15.0006 10.8719 14.9866 10.819 14.9597C10.766 14.9328 10.7202 14.8938 10.6852 14.8459L7.72266 10.7678C7.63359 10.6436 7.72266 10.4701 7.875 10.4701H9.16875C9.29063 10.4701 9.40313 10.5287 9.47344 10.6248L10.9898 12.7131L14.5266 7.84277C14.5969 7.74668 14.7117 7.68809 14.8312 7.68809Z" fill="#E6F7FF" />
                                    <path d="M20.318 3.98228L12.3539 1.26821C12.2578 1.2354 12.1289 1.21899 12 1.21899C11.8711 1.21899 11.7422 1.2354 11.6461 1.26821L3.68203 3.98228C3.4875 4.0479 3.32812 4.2729 3.32812 4.47915V15.7854C3.32812 15.9917 3.46172 16.2635 3.62344 16.3924L11.7023 22.6877C11.7844 22.751 11.8898 22.7838 11.9977 22.7838C12.1055 22.7838 12.2133 22.751 12.293 22.6877L20.3719 16.3924C20.5336 16.2659 20.6672 15.994 20.6672 15.7854V4.47915C20.6719 4.2729 20.5125 4.05024 20.318 3.98228ZM18.9844 15.3354L12 20.7776L5.01562 15.3354V5.31353L12 2.93228L18.9844 5.31353V15.3354Z" fill="#1890FF" />
                                    <path d="M9.47346 10.6245C9.40314 10.5284 9.29065 10.4698 9.16877 10.4698H7.87502C7.72268 10.4698 7.63361 10.6432 7.72268 10.7674L10.6852 14.8456C10.7202 14.8935 10.7661 14.9325 10.819 14.9594C10.8719 14.9863 10.9305 15.0003 10.9899 15.0003C11.0492 15.0003 11.1078 14.9863 11.1607 14.9594C11.2137 14.9325 11.2595 14.8935 11.2946 14.8456L16.2774 7.9854C16.3664 7.86118 16.2774 7.68774 16.125 7.68774H14.8313C14.7117 7.68774 14.5969 7.74634 14.5266 7.84243L10.9899 12.7127L9.47346 10.6245Z" fill="#1890FF" />
                                </svg>
                                Training &  <br /> Specialization
                            </Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Link to={`/my-profile/research-publication`} className={`nav-link ${getMenuItemActive("/my-profile/research-publication")}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5.01562 5.31387V15.3357L12 20.7779L18.9844 15.3357V5.31387L12 2.93262L5.01562 5.31387ZM14.8312 7.68809H16.125C16.2773 7.68809 16.3664 7.86152 16.2773 7.98574L11.2945 14.8459C11.2595 14.8938 11.2137 14.9328 11.1607 14.9597C11.1078 14.9866 11.0492 15.0006 10.9898 15.0006C10.9305 15.0006 10.8719 14.9866 10.819 14.9597C10.766 14.9328 10.7202 14.8938 10.6852 14.8459L7.72266 10.7678C7.63359 10.6436 7.72266 10.4701 7.875 10.4701H9.16875C9.29063 10.4701 9.40313 10.5287 9.47344 10.6248L10.9898 12.7131L14.5266 7.84277C14.5969 7.74668 14.7117 7.68809 14.8312 7.68809Z" fill="#E6F7FF" />
                                    <path d="M20.318 3.98228L12.3539 1.26821C12.2578 1.2354 12.1289 1.21899 12 1.21899C11.8711 1.21899 11.7422 1.2354 11.6461 1.26821L3.68203 3.98228C3.4875 4.0479 3.32812 4.2729 3.32812 4.47915V15.7854C3.32812 15.9917 3.46172 16.2635 3.62344 16.3924L11.7023 22.6877C11.7844 22.751 11.8898 22.7838 11.9977 22.7838C12.1055 22.7838 12.2133 22.751 12.293 22.6877L20.3719 16.3924C20.5336 16.2659 20.6672 15.994 20.6672 15.7854V4.47915C20.6719 4.2729 20.5125 4.05024 20.318 3.98228ZM18.9844 15.3354L12 20.7776L5.01562 15.3354V5.31353L12 2.93228L18.9844 5.31353V15.3354Z" fill="#1890FF" />
                                    <path d="M9.47346 10.6245C9.40314 10.5284 9.29065 10.4698 9.16877 10.4698H7.87502C7.72268 10.4698 7.63361 10.6432 7.72268 10.7674L10.6852 14.8456C10.7202 14.8935 10.7661 14.9325 10.819 14.9594C10.8719 14.9863 10.9305 15.0003 10.9899 15.0003C11.0492 15.0003 11.1078 14.9863 11.1607 14.9594C11.2137 14.9325 11.2595 14.8935 11.2946 14.8456L16.2774 7.9854C16.3664 7.86118 16.2774 7.68774 16.125 7.68774H14.8313C14.7117 7.68774 14.5969 7.74634 14.5266 7.84243L10.9899 12.7127L9.47346 10.6245Z" fill="#1890FF" />
                                </svg>
                                Research & <br /> Publication
                            </Link>
                        </Nav.Item>

                        

                        <Nav.Item>
                            <Link to={`/my-profile/additional-info`} className={`nav-link ${getMenuItemActive("/my-profile/additional-info")}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M9.06384 6.00024H4.64587V18.0002H20.0209V8.68149H11.867L9.06384 6.00024ZM12.9896 10.3854V12.3752H14.9701C15.0662 12.3752 15.1459 12.4479 15.1459 12.5393V13.5237C15.1459 13.6127 15.0662 13.6877 14.9701 13.6877H12.9896V15.6776C12.9896 15.769 12.917 15.844 12.8256 15.844H11.8412C11.7521 15.844 11.6771 15.769 11.6771 15.6776V13.6877H9.69666C9.60056 13.6877 9.52087 13.6127 9.52087 13.5237V12.5393C9.52087 12.4479 9.60056 12.3752 9.69666 12.3752H11.6771V10.3854C11.6771 10.294 11.7521 10.219 11.8412 10.219H12.8256C12.917 10.219 12.9896 10.294 12.9896 10.3854Z" fill="#E6F7FF" />
                                    <path d="M20.9584 6.99399H12.5443L9.79509 4.36431C9.76011 4.33156 9.7141 4.31315 9.66619 4.31274H3.70837C3.29353 4.31274 2.95837 4.6479 2.95837 5.06274V18.9377C2.95837 19.3526 3.29353 19.6877 3.70837 19.6877H20.9584C21.3732 19.6877 21.7084 19.3526 21.7084 18.9377V7.74399C21.7084 7.32915 21.3732 6.99399 20.9584 6.99399ZM20.0209 18.0002H4.64587V6.00024H9.06384L11.867 8.68149H20.0209V18.0002Z" fill="#1890FF" />
                                    <path d="M11.6771 10.3854V12.3752H9.69666C9.60056 12.3752 9.52087 12.4479 9.52087 12.5393V13.5237C9.52087 13.6127 9.60056 13.6877 9.69666 13.6877H11.6771V15.6776C11.6771 15.769 11.7521 15.844 11.8412 15.844H12.8256C12.917 15.844 12.9896 15.769 12.9896 15.6776V13.6877H14.9701C15.0662 13.6877 15.1459 13.6127 15.1459 13.5237V12.5393C15.1459 12.4479 15.0662 12.3752 14.9701 12.3752H12.9896V10.3854C12.9896 10.294 12.917 10.219 12.8256 10.219H11.8412C11.7521 10.219 11.6771 10.294 11.6771 10.3854Z" fill="#1890FF" />
                                </svg>
                                Additional <br /> Information
                            </Link>
                        </Nav.Item>
                    </Nav>
 
                </div>
            </div>
        </>
    )
} 