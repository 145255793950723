import React from 'react'
import { Link } from "react-router-dom";
import { calculateDateRangeCount } from '../../helpers/remainingDays';

export function JobsCard2({ jobData }) {
   
    const rangeCount = calculateDateRangeCount(jobData?.hrms_job__application_deadline);

    return (
        <Link to={`/jobs-details/${jobData?.hrms_job__id}`} className="jobs_card_style2">
            <h3>{jobData?.hrms_job__job_title}</h3>
            <div className="dept">
                <p>Dept: <span>{jobData?.sys_departments__department_name}</span></p>
                {/* <p>Full Time</p> */}
                <p className="ps-3">Exp: 
                    <span>
                        {/* {jobData?.hrms_job__minimum_experience}  yrs  */}
                        {jobData?.hrms_job__minimum_experience>0?jobData?.hrms_job__minimum_experience+' yrs':"Fresher"}
                    </span>
                </p>
            </div>
            <div className="dept">
                <p>
                    Salary:
                    <span>{
                        jobData?.hrms_job__salary === "salary_range" ?
                            `: ${jobData.hrms_job__min_salary} - ${jobData.hrms_job__max_salary} BDT`
                            : jobData?.hrms_job__salary === "fixed" ? jobData?.hrms_job__fixed_salary
                                : jobData?.hrms_job__salary}
                    </span>
                </p> 
            </div>
            <div className="d-flex justify-content-between">
                    {/* <p> Position: <span>{jobData?.hrms_job__position_status}</span>  </p> */}
                    <p style={{fontWeight: "700"}}><i className="fas fa-briefcase"></i> <span>{jobData?.hrms_job__position_category}</span></p>
                    
                    {rangeCount && rangeCount >0 ? (
                        <p className="expried_day text-danger">Remaining {rangeCount} days</p>
                    ): ''}
                </div>
            {/* <button className="btn btn_job_card">{jobData?.hrms_job__position_status}</button> */}
        </Link>
    )
} 