import React from "react";
import { Link } from "react-router-dom";
import { calculateDateRangeCount } from "../../helpers/remainingDays";

export function JobsCardComponent({
    jobId,
    title,
    dept,
    exp,
    type,
    salary,
    min_salary,
    max_salary,
    fixed_salary,
    application_deadline
}) {

    const rangeCount = calculateDateRangeCount(application_deadline);

    return (
        <div className="col mb-5">
            <div className="card jobs_component_card">
                <Link to={`jobs-details/${jobId}`} className="jobs_card">
                    <h3>{title.length > 20 ? title.slice(0, 20) + '...' : title}</h3>

                    <div className="department_box">
                        <div className="dept_lable">
                            <p>Department </p>
                        </div>
                        <div className="dept_content d-flex justify-content-between">
                            <div>
                                <p>
                                    <strong> : </strong>
                                    <span> {dept.length > 20 ? dept.slice(0, 20) + '...' : dept}</span>
                                </p>
                            </div>
                            <div>
                                <p className="ps-3">Exp: <span>{exp>0?exp:"Fresher"}</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="department_box">
                        <div className="dept_lable">
                            <p>Salary </p>
                        </div>
                        <div className="dept_content">
                            <p>
                                <strong> : </strong>
                                <span>
                                    {salary === "salary_range" ?
                                        `: ${min_salary} - ${max_salary} BDT`
                                        : salary === "fixed" ? fixed_salary
                                            : salary}
                                </span>
                            </p>
                        </div>
                    </div>
 
                    <div className="salary"> 
                        <div className="d-flex justify-content-between">
                            {type ? <p style={{ fontWeight: "700" }}><i className="fas fa-briefcase"></i> <span>{type}</span></p> : <p></p>}

                            {rangeCount && rangeCount>0 ? (
                                <p className="expried_day text-danger">Remaining {rangeCount} days</p>
                            ) : ''}
                        </div> 
                    </div> 
                </Link>
            </div>
        </div>
    )
} 