import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { TitleCard } from '../../../components/cards/TitleCard';
import { getBlackListReport, downloadBlackListReport } from '../_redux/hrRequest'
import { saveAs } from 'file-saver';

export default function BlacklistApplicants() {
    const [ dataSource, setDataSource ] = useState([]);
    const { Column } = Table;

    const paginationConfig = {
        total: dataSource?.length,
        pageSize: 10,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    };

    const getBlackListApplicants = async () => {
        try{
            const { data: { data } } = await getBlackListReport();
            setDataSource(data);
        } catch(err) {
            console.error(err)
        }
    }

    useEffect(() => {
        getBlackListApplicants();
    }, []);

    const downloadExcelFile = () => {
        downloadBlackListReport().then((res) => {
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'Blacklist applicants.xlsx');
        }).catch(err => {
            console.error(err);
        })
    }

    return (
        <>
            <div className="hr-admin-table-job-list">
                <nav>
                    <ol className="breadcrumb bg-transparent">
                        <li className="breadcrumb-item"> <Link to="/hr-admin/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item"> <span className="text-dark">Blacklist Report</span></li>
                    </ol>
                </nav>

                <TitleCard title="Blacklist Report">

                    <div className='row py-5'>
                        <div className='col-md-12 text-right'>
                            <Button type='primary' onClick={downloadExcelFile}>
                                Export to XLSX
                            </Button>
                        </div>
                    </div>

                    <Table
                        dataSource={dataSource}
                        scroll={{ x: true }}
                        pagination={paginationConfig}
                        bordered={true}
                        rowKey='id'
                        expandable={{
                            expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.blacklisted_comment}</p>,
                            expandIconColumnIndex: 9,
                            expandIconAsCell: false,
                            rowExpandable: (record) => record?.blacklisted_comment?.length > 50
                        }}
                    >   
                        <Column
                            title = 'Name'
                            dataIndex = 'full_name'
                        />
                        <Column
                            title = 'Email'
                            dataIndex = 'email'
                        />
                        <Column
                            title  = 'Phone'
                            dataIndex = 'contact_number'
                        />
                        <Column
                            title = 'Addess'
                            dataIndex = 'address'
                        />
                        <Column
                            title = 'NID No'
                            dataIndex = 'nid'
                        />
                        <Column
                            title = 'Highest Education Level'
                            dataIndex = 'highest_education'
                        />
                        <Column
                            title = 'Total Experience(years)'
                            dataIndex = 'total_experience'
                        />
                        <Column
                            title = 'Position Applied For'
                            dataIndex = 'position'
                        />
                        <Column
                            title = 'Date Blacklisted'
                            dataIndex = 'blacklisted_date'
                        />
                        <Column
                            title = 'Reason of Blacklist'
                            dataIndex = 'blacklisted_comment'
                            render={(item) => {
                                return item?.substring(0, 50)
                            }}
                        />
                    </Table>
                </TitleCard>
            </div>
            </>
    )
}
