import React from 'react'

export default function MapChart({ divisionPercentages }) {
    return (
        <><svg
            className="map_size"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-1719.3 952.2 754.5 896"
            style={{ enableBackground: 'new -1719.3 952.2 754.5 896', whiteSpace: 'pre' }}
        >
            {divisionPercentages && divisionPercentages?.length > 0 && divisionPercentages.map((item, key) => (
                item.division_name === "Rajshahi" ?
                    <>
                        <a>
                            <path
                                id="XMLID_54_"
                                className="map mapRajshahi"
                                d="M-1346.8,1305.8c-0.5-0.4-0.7-1.4-0.8-2.2c-0.5-5.8-0.6-11.7-1.6-17.4c-0.4-2.2-2.4-4-0.7-6.5
                                                     c0.3-0.4-0.6-1.7-1.2-2.6c-1.6-2.5-3.9-4.6-1.1-7.9c0.9-1,1.4-3.4,0.9-4.4c-1.5-2.7-0.1-4.1,1.5-5.6c1.8-1.7,3-3.5,1.3-6.1
                                                     c-0.4-0.6-0.1-1.9,0.3-2.7c1.5-3.5,4-6.9,1-10.8c-0.2-0.3-0.2-1,0-1.4c0.9-2.8-0.9-3.1-3-3.6c-1.3-0.3-3.5-1.8-3.4-2.2
                                                     c0.6-2.3-0.8-2.9-2.1-4c-2.7-2.2-4.8-8.1-3.9-10c0.5-1.3,1.3-2.7,1.1-3.9c-0.6-3.5-1.5-7-2.5-11.4c-2.5,0.9-4.6,2-6.8,2.5
                                                     c-2,0.4-4.2,0.5-6.1,0c-1.8-0.5-3.3-2-5.1-2.8c-1.3-0.6-3.3-1.4-4.1-0.8c-4,2.7-6-0.3-7.8-2.7c-1.7-2.1-3.1-2.5-5.6-1.5
                                                     c-2.7,1.2-5.2,0.9-6.4-2.5c-0.3-0.8-0.8-1.7-1.4-2.1c-4-2.5-5.5-6.1-4.6-10.4c-3.1-0.4-5.9-0.6-8.6-1.1c-1.8-0.3-3.4-1.1-5.1-1.6
                                                     c-2.6-0.8-5.2-1.5-7.8-2.3c-0.7-0.2-1.6-0.5-2.3-0.3c-0.4,0.1-0.7,1.3-0.6,1.9c0.5,4-1,6.9-4.7,8.9c-2.2-1.2-4.5-2.7-6.9-3.7
                                                     c-0.6-0.3-1.7,0.6-2.6,1c-1.3,0.5-2.8,1.6-4,1.5c-2.7-0.3-5.9,0.4-7.8-2.7c-0.4-0.6-2-0.6-3.1-0.6c-2,0-4.1,0.2-6.1,0.5
                                                     c-1.4,0.2-3.1,0.5-3.9,1.4c-1.7,1.7-2.8,1.6-4.6,0.2c-4-3.1-6.6-2.9-8.9-0.7c-0.4,3-0.7,5-0.8,7c-0.3,3-1.3,6.3-0.4,8.9
                                                     c0.9,2.7-0.5,3.8-1.4,5.5c-0.5,1-1.3,1.8-1.7,2.9c-1.9,5-2.9,5.7-7.9,4.5c0,2,0,3.8,0,5.8c-3.2,3.1-6.1,4.3-9.6-2.1
                                                     c-3.5-6.5-7.5-6.2-10.8-2.3c0.6,2.4,1.3,4.3,1.4,6.2c0,1.1-1,2.2-1.6,3.3c-1.5,2.4-2.5,5.5-4.6,7c-2.8,2-3.1,4.8-4.3,7.4
                                                     c-0.4,0.9-1.2,1.5-1.8,2.3c-1.1,1.3-2.1,2.6-2.8,3.4c2.5,3.9,4.8,7.3,7,10.7c3.7,5.8,8.4,10.8,14.6,13.6c3.7,1.7,6.7,3.9,9.8,6.5
                                                     c2,1.7,5.3,2.9,7.8,2.6c5-0.6,13.9,2.6,16.3,6.9c1.2-0.4,2.4-1.3,3.5-1.2c2.9,0.3,5.7,1.4,8.6,1.4c6.2,0,9.6,4,8.3,10.2
                                                     c-0.2,1-0.2,2.2,0.1,3.1c2.2,5.8,6.9,8.3,12.7,8.9c2.2,0.2,4.6-1.3,6.9-1.3c8.4,0.1,10,1.4,12.6,9.4c0.7,2.1,1.4,4.1,2.2,6.2
                                                     c0.2,0.6,0.5,1.6,1,1.8c3.8,1.6,7.4,5.4,11.8,1.6c1,1.5,1.8,2.8,2.8,4.5c0.8-1,1.6-1.7,1.8-2.5c0.5-2.9,2.2-2.9,4.2-2.1
                                                     c1.7,0.7,3.4,1.4,4.9,2.4c1.6,1.1,2.7,2.8,4.3,3.9c4.1,2.9,8.4,5.7,12.6,8.4c2.3,1.5,4.7,3.3,7.2,3.8c3.9,0.6,7.9,0.4,11.9,0.1
                                                     c2.4-0.2,4.7-1,7.1-1.6c0.1-0.5,0.1-0.9,0.2-1.4c-1.1-1-2.2-2.1-2.7-2.5c-0.6-3-1-5.2-1.6-7.5c-0.7-2.6,0.8-8.4,3-9.9
                                                     c1.8-1.1,3.9-1.7,5.8-2.7C-1343.1,1316.3-1342.6,1309.4-1346.8,1305.8z"
                            ></path>
                        </a>
                        <a xlinkTitle="Rajshahi">
                            <text id="XMLID_9_" transform="matrix(1 0 0 1 -1457.1841 1261.0067)" className="map1 map2 map3"> {item.percentage}%</text>
                        </a>
                    </>

                    : item.division_name === "Rangpur" ?
                        <>
                            <a key={'Rangpur' + item?.key}>
                                <path
                                    id="XMLID_58_"
                                    className="map mapRangpur"
                                    d="M-1406.7,1181c-7.6-1.3-14.8-2.6-22.1-3.9c2.1-4.9,1.7-6.2-3.8-6.6c-4.1-0.3-6.5-3-9.7-4.6
                                                     c-3.4-1.7-4.5-7.3-3.9-11.1c0.3-1.7-0.8-3.6-1.3-5.4c0-0.1-0.6-0.2-0.6-0.3c-0.3-4.9-3.8-3.8-7.1-3.6c-0.1,0.8-0.3,1.7-0.5,2.7
                                                     c-0.3-0.1-0.6-0.2-0.9-0.3c-2.1-0.9-3.6-0.8-4.8,1.6c-0.4,0.7-2.7,1.3-3.3,0.8c-2.8-2.1-5.3-3.9-9.2-3.6c-1.6,0.1-3.5-2.2-5.1-3.6
                                                     c-1.3-1.1-2.5-2.4-3.3-4c-3.4-0.8-5.3-2.2-3.9-6.4c0.7-2.1-0.2-4.1-2.9-4.5c-1.1-0.2-2.3-0.9-3.2-1.6c-4.2-3.5-8.4-7-12.5-10.6
                                                     c-0.4-0.4-0.9-1-0.9-1.4c0-3.8-2.4-3.3-4.8-2.7c-2.6,0.7-5.2,1.5-7.8,2.1c-2.1,0.5-5.1-2.7-5-5c0.1-1.6,0.1-3.3-0.2-4.9
                                                     c-0.4-2.4,0.1-5.1,1.5-9.1c1.3-3.4,2.7-6.4,7.3-7.7c-0.8-2.7-2-5.3-2.1-8c0-2.2,0.8-5.2,2.4-6.3c3-2.3,6.6-3.9,10.2-5.1
                                                     c5.5-1.8,6.7-3.3,7.1-9c0.1-1.5,1.2-3.1,2.3-4.2c2.1-1.9,4.5-3.6,6.9-5.1c0.7-0.5,1.9-0.1,2.8-0.2c1.7-0.2,4.3,0,4.9-0.9
                                                     c0.8-1.3,0.3-3.6-0.1-5.4c-0.3-1.6-1.1-3.2-1.9-4.6c-1.2-2.2-2.2-4.3-5.6-4.1c-2,0.1-4.1-1.6-6.3-1.9c-1.6-0.3-3.4,0.3-5,0.5
                                                     c1.8-4.7,3.7-9.7,5.6-14.6c0.2,0,0.5,0,0.7,0.1c0.3,1.3,0.3,2.6,0.9,3.8c1.1,2.3,1.1,6.3,5.6,4.7c0.4-0.1,1.2,0.6,1.7,1.1
                                                     c2.6,2.9,4.9,6.2,7.9,8.7c1,0.8,4.3,3,5.4-0.5c0.7,1.1,1.2,2.8,2.3,3.4c4.7,2.5,5.7,7.3,7.3,11.9c1.1-0.5,1.9-0.9,2.6-1.2
                                                     c1.9-0.9,3-1.4,2.9,1.8c-0.1,4.5,3.5,2.2,4.9,3c1.6,2.6,3,5.5,5.1,7.7c0.8,0.8,3.3-0.1,4.3-0.2c0.3-2.4,0-5.1,0.8-5.5
                                                     c2.2-1,4.4-0.2,5.5,2.4c1.2,2.7,3.2,3.4,6,2.5c1.6-0.5,3.3-0.2,5-0.5c1-0.2,2.5-0.6,2.7-1.1c0.4-1.6,0.3-3.4,0-5.1
                                                     c-0.1-0.3-1.8-0.6-2.7-0.5c-1.9,0.1-3.1-1.6-3.7-3.6c-0.4-1.4-1.8-2.9-3.2-3.7c-1.7-1-3.8-1.3-6.2-2.1c1.8-2.9,3.3-5.5,4.6-7.6
                                                     c2,1.4,3.8,3,5.9,4.1c2.1,1.2,4.5,2,6.1,2.7c0.6,1.6,1.2,2.6,1.3,3.8c0.3,5.8,2.9,10.8,4.8,16.1c0.8,2.4-0.2,5.2,1.2,7.9
                                                     c1.4,2.5,3,4.1,5.9,4c3.4-0.1,4.4,2.3,5.4,4.8c0.2,0.5,0,1.5,0.4,1.7c3.8,2.4,7.5,4.9,11.6,6.8c1.3,0.6,3.4-0.7,5.9-1.3
                                                     c2.4,3.7,6.8,2.3,11.1,1.5c0.6,1.5,0.8,3.5,1.8,4.1c1.1,0.6,3,0,4.3-0.6c0.7-0.3,1.5-1.5,1.6-2.4c0.3-6.1,0.3-6.1,6.3-8.3
                                                     c-0.6-2.3-1.3-4.6-1.9-7c-0.5-1.9-0.7-3.5,1.5-5c0.9-0.6,0.3-3.6,0.5-6.4c0.9,1.9,2.3,3.4,2.1,4.7c-0.5,3.4,1.4,3.5,3.6,3.4
                                                     c2.9-0.1,5.3,0,4.3,4.1c-0.1,0.4,0.6,1.1,1.1,1.6c0.4,0.4,1.3,0.8,1.3,1.1c-0.1,4.2,2.8,7.3,4,11c0.4,1.2,1.2,2.2,1.9,3.5
                                                     c-1.7,3.8-0.9,4.7,4.1,4.3c-1.9,3.8-3.9,7.7-5.9,11.6c-0.3,0.5-0.8,1.4-0.7,1.6c4.4,5.6,0.3,13.5,5.7,19c3.5,3.5,0.2,7.9-0.2,11.9
                                                     c-0.3,3.1-1.2,6.1-1.6,9.2c-0.7,4.8-1.3,5.4-6.1,4.4c-0.5-0.1-1-0.1-1.5,0c-0.3,0.1-0.6,0.3-1.1,0.6c1.7,2.7,1.1,4.3-1.9,5.7
                                                     c-2.3,1.1-3.2,3-1.9,6.1c1.4,3,1.5,6.6,2.2,10.4c-7.3-0.4-7.4,5.3-9.3,9.2c-2,4-3,8.2-1.3,12.2c-3.2,1.4-6,2.9-9.1,3.9
                                                     c-1.1,0.4-2.6-0.2-3.7-0.7c-2.7-1.2-5.4-3.9-7.8-3.6c-4.6,0.6-7.7-0.2-10.5-4c-0.6-0.9-3.4-0.1-5.2-0.1c-1.2,0-3.1,0.3-3.3-0.2
                                                     c-2.1-4.5-9.2-6.7-6.2-13.5C-1406.4,1181.8-1406.7,1181.2-1406.7,1181z"
                                ></path>
                            </a>
                            <a xlinkTitle="Rangpur" key={'Rangpur1' + item?.key}>
                                <text id="XMLID_8_" transform="matrix(1 0 0 1 -1457.1841 1119.922)" className="map1 map2 map3">{item.percentage}%</text>
                            </a>
                        </>
                        : item.division_name === "Chattogram" ?
                            <>
                                <a key={'Chattogram' + item?.key}>
                                    <g id="XMLID_1_">
                                        <path
                                            id="XMLID_53_"
                                            className="map mapChittagong"
                                            d="M-1194.1,1350.5C-1194.2,1350.5-1194.2,1350.5-1194.1,1350.5c-0.1,0-0.1,0-0.1,0s0,0,0,0.1
                                                       C-1194.2,1350.5-1194.2,1350.5-1194.1,1350.5z"
                                        ></path>
                                        <path
                                            id="XMLID_52_"
                                            className="map mapChittagong"
                                            d="M-1041.6,1630.1c-0.7-3.3-2-6.5-2.7-9.8c-3.2-15.6-5.1-31.4-3-47.3c0.4-2.9,1.4-5.8,1.2-8.6
                                                       c-0.2-4.6-1.1-9.2-1.8-13.7c-0.5-3.2-1.2-6.4-2.2-9.4c-1.6-4.6-1.8-4.6,2.8-6.4c-0.5-3.8-0.8-7.7-1.4-11.5
                                                       c-1.2-7.5-2.5-15.1-3.8-22.6c-0.2-1.3-0.3-2.7-0.6-4c-0.7-3.1-2.6-6.5-2.1-9.4c1.1-5.9-0.8-11-2.5-16.2c-1.5-4.4-4.8-8.5-5.1-12.9
                                                       c0.5-4.2-8-25.2-8-25.2c-0.3-1.6-0.6-3.3-0.8-4.9c-0.4-2.6-0.8-5.2-1.2-7.8c-0.1-0.9,0.2-2-0.1-2.8c-1.8-5.9-1.6-6.5,3.4-9.2
                                                       c-1.4-4.8-2.8-9.6-4.3-14.5c-0.7-2.3-1.5-4.5-2.2-6.8c-1.6-5.8-3.3-11.5-4.7-17.3c-0.6-2.7-2-5.3-0.6-8.4c0.8-1.9,0.9-4.6-1.9-5.4
                                                       c-0.9,2.2-1.5,4.3-2.7,6c-1.1,1.5-3.5,1.6-3.4-0.6c0.3-3.8-3.4-4.6-4.2-7.8c-2.7,3.3-4.9,6.2-7.4,8.9c-0.9,0.9-2.5,2.1-3.4,1.9
                                                       c-3.3-0.7-6.4-2.1-7.4-5.8c-0.3-1.1-1.1-2-1.7-3.1c-2.3,3-3,5.9-1.8,9.2c0.4,1.2,0.3,2.6,0.4,3.9c0.6,3.8,1.1,7.6,1.8,11.4
                                                       c0.3,1.8-3.2,8.3-4.9,8.7c-0.2,0.1-0.5,0-0.7,0c-1.9,0.7-4.1,1-5.5,2.3c-2,1.8-3.4,4.2-4.8,6.5c-1.7,2.9-3.3,5.9-4.7,9
                                                       c-0.4,0.9-0.1,2.3,0.1,3.3c1.4,5.9,3,11.9,4.3,17.8c0.2,0.8-0.6,2.7-1,2.7c-3.8,0.1-3.9,3.9-5.8,5.8c-1.5,1.4-3.1,2.9-5,3.7
                                                       c-2.7,1.1-6.1,0.9-8.2,2.6c-3.5,2.7-9.5-0.5-10.3-3.9c-0.4-1.7-0.6-3.5-0.9-5.2c-0.8-5-6.2-18.7-9-22.4c-1.4-1.9-2.7-1.8-3.8,0.5
                                                       c-2.9,5.9-0.7,11.4,1.2,16.9c0.4,1.1,0.4,3-0.2,3.5c-0.9,0.7-2.9,0.9-3.7,0.3c-1.6-1.1-3.2-2.7-4-4.5c-2.1-5-4.1-10.2-3.8-15.9
                                                       c0.1-1.9-0.5-3.8-0.9-5.8c-0.5-2.7-1.1-5.3-1.7-7.9c-0.7-2.9-1.3-6-2.7-8.7c-1.9-3.7-2.1-8.6-6.8-10.5c-0.2-0.1-0.4-0.6-0.5-0.9
                                                       c-0.6-1.9-0.6-4.2-1.7-5.5c-2.9-3.3-3-7.1-3-11c0-0.6,0.2-1.2,0.1-1.8c-1.3-7.4-0.6-8.4,6.7-9.1c1-2.9,1.9-5.3,2.7-7.6
                                                       c0.2-0.7,0.4-1.4,0.3-2.1c-0.8-3.3-1.6-6.5-2.4-9.4c1.8-1.1,3.2-1.4,3.7-2.3c1.2-1.8,2.2-3.7,3-5.8c0.7-1.9,2.1-4.7,1.3-5.7
                                                       c-3.7-4.9-4.8-9.9-3-15.8c0.4-1.3-0.3-2.9-0.3-3.4c-3.6-1.5-6.3-2.6-9-3.7c-0.5-0.2-1.2-0.1-1.7,0c-2.4,0.7-4.7,1.5-7.1,2.3
                                                       c0.2,0.2,0.5,0.4,0.7,0.6c-0.9,0.9-1.9,2.8-2.5,2.7c-4.5-1.1-5.9,2.9-9.1,4.9c1.3,1.1,2.3,2,3.4,2.7c5.1,3,5.2,5.9,0.1,9.2
                                                       c-3.2,2.1-6,4.2-5.2,8.7c0.1,0.6-0.2,1.4-0.5,2.1c-1.6,3.1-3.1,6.4-5.1,9.3c-1.1,1.6-4,3.8-4.7,3.4c-4.1-2.6-7.9,0.7-12.6-0.7
                                                       c2,5.1,0.2,8.5-2.3,11.8c-0.7,1,0.4,3.2,0.3,4.8c0,1.8,0.1,3.9-0.8,5.4c-1.2,2-3,0.9-4.8,0.2c-1.1-0.4-3.1-0.3-3.9,0.4
                                                       c-1.4,1.3-1.9,3.1,0.6,4.4c0.7,0.4,1.7,1.8,1.4,2.4c-1,2.5,0.8,3.5,2.1,4.9c0.7,0.8,1.3,1.9,1.4,2.9c0.2,1.4,0.5,3.5-0.2,4.1
                                                       c-1,0.8-3,0.9-4.5,0.7c-2.6-0.5-6.9,1-8,3.3c-1,2.2-2.1,4.4-2.9,6.6c-0.5,1.3-1,3.6-0.4,4.1c3.8,3.4,2.1,8.8,4.9,12.4
                                                       c0.6,0.9,0.9,2.5,1.6,2.7c3.7,1,2.9,3.9,3.1,6.4c0.1,1.1,0.7,2.1,0.8,3.2c0.1,2,0,4-0.1,5.9c-0.2,3.5-0.7,7-0.5,10.5
                                                       c0.1,1,1.9,2,3,2.8c1,0.7,2.5,1.1,3.1,2c1.9,3,3.2,6.3,5.2,9.1c2.3,3.3,4.2,6.4,3.7,10.7c-0.2,1.4,0.7,3,1.1,4.5
                                                       c1.7,7.3,8.4,9.9,13.5,13.7c5,3.7,10.1,7.2,13.9,12.2c0.4,0.5,1,0.9,1.8,1.6c2.4-3.1,5.1-5,9.1-3.5c1,0.4,8.6-1.7,9-2.6
                                                       c0.2-0.4,0.3-1,0.1-1.3c-2-3.3-0.5-5.8,2.1-8c-3.2-3.1-6.3-6-9.3-8.9c0.3-0.5,0.5-0.9,0.8-1.4c2.5,0.9,5.1,1.8,6.9,2.4
                                                       c3.3-1.7,6.3-3.3,9.3-4.9c0.4-0.2,1.2-0.3,1.3-0.6c1.8-4.6,4.4-2.7,7.1-0.4c-1.8-5.9-1.5-7.3,1.6-8.6c0.2,1.6,0.2,3.3,0.7,4.8
                                                       c0.7,2,1.6,4,2.7,5.9c0.9,1.6,2,3.2,3.4,4.4c3.3,3,6.9,5.7,10.2,8.7c1.7,1.6,2.8,4,4.5,5.6c8.1,7.4,11.1,17.3,13.9,27.5
                                                       c1.2,4.4-0.7,10.4,5.5,12.8c0.2,0.1,0.4,0.6,0.5,1c0.5,4.4,0.5,8.5,4.5,12.1c2.3,2,1.7,7.3,2.3,11.1c0.3,1.6,0.1,3.3,0.7,4.9
                                                       c2.5,7.2,5,14.3,1.6,22c-1.3,3-2.4,6.1-3.5,9.2c-0.7,2-2.1,4.2,1.6,5.1c0.5,0.1,0.8,2.2,0.6,3.2c-0.6,2.3-1.6,4.6,1.7,5.9
                                                       c-1.5,2.3-2.9,4.3-4.5,6.6c0.5,0.5,1.3,1.4,1.4,1.6c2.5-0.3,4.2-0.7,6-0.7c2.4,0,3.5-0.8,3.5-3.4c0-3.2,0.4-6.3,0.8-9.5
                                                       c0.2-1.1,1-2.1,1.5-3.1c0.4,0.1,0.7,0.2,1.1,0.3c1.9,5.8,0.5,11.7-0.5,17.5c-0.1,0.8-0.6,1.8-1.2,2.2c-3.2,1.9-2,3.6-0.3,5.9
                                                       c4.3,5.6,8.6,11.5,7.6,19.1c-0.8,6.3,1.7,10.9,5.7,15.3c3.5,3.8,7.7,7.9,9.1,12.6c2.9,10.1,10.1,17.8,13.5,27.4
                                                       c0.1,0.1,0.4,0.2,1.8,0.7c-2.1-5.4-3.4-10.5-5.9-14.7c-2-3.2-3.4-6.3-3.5-10c-0.2-5.9-0.5-11.9-4.1-16.9
                                                       c-4.1-5.9-4.2-6.6,0.4-11.8c-0.7-2-1-4.1-2-5.9c-2-3.8,0.1-5.8,2.8-8c1.1-0.9,1.5-2.5,2.6-3.4c1.8-1.4,0.3-5.7,4-4.9
                                                       c2.6,0.6,3.5-0.3,4.7-2.4c1.9-3.3,5.8-4.3,7.5-1.9c1.6,2.2,3,4.8,3.6,7.4c1,4.5,3.5,5.8,7.9,4c3.8-1.5,5.8-2.6,7.1,3.6
                                                       c0.6,3.1,2.3,6,3.4,8.9c1.4-1.1,2.7-1.8,3.6-3C-1042.1,1632.8-1041.4,1631.2-1041.6,1630.1z"
                                        ></path>
                                    </g>
                                </a>
                                <a xlinkTitle="Chittagong" key={'Chattogram1' + item?.key}>
                                    <text id="XMLID_6_" transform="matrix(1 0 0 1 -1153.0127 1469.65)" className="map1 map2 map3">{item.percentage}%</text>
                                </a>
                            </>
                            : item.division_name === "Dhaka" ?
                                <>
                                    <a key={'Dhaka' + item?.key}>
                                        <path
                                            key={'Dhaka2' + item?.key}
                                            id="XMLID_59_"
                                            className="map mapDhaka"
                                            d="M-1193.1,1284.7c-3.5-3-2.2-5.1,1.5-6.6c-1.3-1-2.4-1.5-2.8-2.3c-0.4-0.8-0.1-2,0.1-3
                                                     c0.2-0.9,0.5-1.7,0.8-2.6c-0.9,0.1-1.7,0-2.6,0.2c-0.7,0.1-1.3,0.5-1.3,0.5c-1.9-1.2-3.2-2.1-5.3-3.5c1.7-1,3.2-2.1,4.8-2.8
                                                     c2.9-1.3,3.9-5.3,2-7.8c-1.3-1.7-2.4-3.7-3.4-5.6l-0.2-0.1l-14.8,16.2l-6.1,7.5h-19.6v10.8l-17.5-2.5l-1,6.6l3.6,11.2h-17.3
                                                     l-5.6,6.7l-8.8-6.8l-16.2-5.8l-10.5-17.4v-16.7l-15.8-18.3l-2.5,4.7l-16,13.8l-0.4-0.5l0,0c-0.6,0.7-1.3,2.2-1,2.6
                                                     c1.7,2.6,0.8,4.7-0.9,6.6c-1.7,2-0.8,3.2,0.4,5c1.1,1.7,1.2,4,1.7,6.1c0.2,1,0.2,2,0.3,3c0.5,3.5,2.6,6.4,2,10.5
                                                     c-0.5,3.3,0.6,7.3,2.2,10.5c3.9,7.9,1.6,13.8-5.8,16c-2.8,0.8-5.4,2.6-4.9,5.9c0.6,4.3,1.3,8.7,3,12.6c1.1,2.6,1,4.2-1.4,4.8
                                                     c-3.7,1-7.5,1.3-11.2,2.1c-6.5,1.4-11.8-0.5-16.7-4.8c-2.6-2.3-5.5-4.2-8.5-6.4c-1.9,5.9-3.4,11.6-5.6,17c-1.4,3.3,0.9,4.8,2.1,6.9
                                                     c1.2,2,2.9,2.2,5,1.2c0.8-0.4,2.3,0,3.1,0.5c2.4,1.5,4.8,3.2,7,4.9c0.5,0.4,1,1.2,0.9,1.8c-0.4,3.9,1.5,6.5,4.3,8.8
                                                     c0.2,0.2,0.3,0.6,0.5,1c-0.9,0.6-1.7,1.1-2.7,1.8c6.9,1.3,9.8,4.4,10.4,10.7c0.1,1,0.6,1.9,0.7,2.8c0.1,0.8,0.2,2-0.3,2.4
                                                     c-3.5,2.6-0.6,5,0.2,7.4c0.9,2.4,2.3,4.7,3.7,7.5c6.3-0.6,2.2,5.5,3.2,7.1c3.3,0.2,5.9,0.3,8.5,0.4c-0.8,1.3-1.5,2.6-2.4,4.2
                                                     c2.7,4.5-3.7,2.9-4.2,4.8c1.4,1.7,3.6,3.1,3.6,4.6c0.2,6.4,8.1,7.5,8.8,13.7c0.1,1.4,2.6,2.6,4.1,3.8c2.7,2.1,0.9,3.2-0.8,4.3
                                                     c-0.6,0.4-1.4,0.4-2,0.5c0,0.3,0,0.6,0,0.9c2.6,0.5,5.2,1,7.7,1.4c0.5,0.1,1-0.5,1.6-0.7c1.2-0.4,2.4-1.2,3.6-1.2
                                                     c3.9,0.1,5.3-1.1,6.6-4.7c1.1-3,3.1-5.7,4.7-8.5c1.7-3.2,6.5-3.8,7-8.4c0.1-0.9,3.2-1.5,5-2.1c0.8-0.3,1.7-0.4,2.5-0.3
                                                     c1.1,0.2,2.9,0.5,3.1,1.2c0.9,2.6,1.3,5.5,1.9,8.7c1.2,0.1,2.8,0.4,4.5,0.6c2.2,0.2,2.9-0.7,2.3-2.8c-1.5-5.5-0.2-7.2,5.6-6.4
                                                     c3,0.4,7.1-0.2,7.4,4.7c3.5-0.8,5.9-2.5,7.2-6c0.6-1.7,2.5-3.3,4.2-4.3c2.6-1.5,6.7-11.2,5.5-14c-0.1-0.2-0.2-0.4-0.3-0.7
                                                     c-1.9-4.7-3.9-9.4-5.6-14.2c-0.5-1.4-0.3-3.2,0.1-4.7c0.5-1.9,1.6-3.6,2.3-5.3c2.2-5.1,5.8-6.9,11.2-5.8c0.9,0.2,1.8,0,4.1,0
                                                     c-1.9-2.8-2.8-5.3-4.6-6.3c-2.6-1.5-3-2.8-1.1-5.2c-4-1.4-3.6-4-2.4-6.9c2.4-5.6,6.3-2.5,10.2-1.3c0-1.6,0.5-3-0.1-3.8
                                                     c-2.5-3.5-1-6.3,1.4-8.8c1.7-1.8,2-3.5,1.3-6c-0.3-1.1,1-3.9,1.8-3.9c4.3-0.4,8.8-2.8,13.2,0.4c1.5-2.4,3.1-4.8,4.5-7.3
                                                     c1-1.8,2.6-3.9,2.4-5.7c-0.6-5.2,2.5-7.7,6.2-10.2c3.9-2.6,3.8-3.6,0-6c-0.9-0.6-1.9-1.2-2.6-2c-1.5-1.7-2.3-3.4,0.4-5
                                                     c2.6-1.5,5.2-3.2,7.4-5.3c1.3-1.3,2.1-2.5,4.3-2.7c2.3-0.1,4.6-1.3,6.8-2c-1.2-2.2-1.7-3.5-1.5-4.6
                                                     C-1199.9,1283.8-1196.3,1285.2-1193.1,1284.7z"
                                        ></path>
                                    </a>
                                    <a xlinkTitle="Dhaka" key={'Dhaka1' + item?.key}>
                                        <text id="XMLID_7_" transform="matrix(1 0 0 1 -1327.3423 1357.6198)" className="map1 map2 map3">{item.percentage}%</text>
                                    </a>
                                </>
                                : item.division_name === "Sylhet" ?
                                    <>
                                        <a key={'Sylhet' + item?.key}>
                                            <path
                                                id="XMLID_51_"
                                                className="map mapSylhet"
                                                d="M-1061.8,1214.4c-3.1-2.6-7.1-4.8-5-9.8c-3-2.4-8-0.9-8.4-5.2c-2.2-0.4-4.1-0.4-5.7-1.1
                                                     c-4.2-1.8-8.2-3.8-12.3-5.8c-1.3-0.6-2.9-1.2-3.6-2.2c-2.2-3.4-5.3-4-8.9-3.8c-3,0.2-6.1,0.9-9,0.6c-8.8-0.9-15.4-0.2-23.8,3.1
                                                     c-0.3,0.1-0.7-0.1-1,0c-1.1,0.3-2.3,0.4-3.2,1c-5.2,3.6-9.9,2.4-11.6-3.1c-0.9,0.6-1.8,1.4-2.8,1.7c-3.2,0.9-7.3,3.2-9.6,2
                                                     c-6-3-12.7-3-18.7-5.4c-1.1-0.4-2.1-1.7-3.1-1.6c-9.2,0.9-18.5-1.1-27.6,2.2c-3.6,1.3-5.5,4.5-4,8.1c1.5,3.8,1.1,4.6-3.4,5.1
                                                     c1.4,1.1,2.4,1.9,4,3.1c-4,1.5-2.3,4.1-1.7,6.4c0.5,2,1.2,4,1.9,6c0.4,1,1.5,1.8,2.7,2.5c0.8,0.4,1.9,1,2.6,0.8
                                                     c5.9-1.4,7.2-0.8,9.9,4.7c0.5,1,1.9,2.1,2.9,2.2c3.3,0.1,4,1.8,3.4,4.5c-0.6,3-1.3,5.9-2,8.9c1.7,0.3,3.1,0.5,4.6,0.7
                                                     c0.9,0.1,2.3-0.1,2.8,0.5c2.3,2.8,2.1,6.6-0.9,9c-2.6,2.1-2.2,4.2-0.4,5.8c2.3,2,2.3,3.6,0.9,5.9c-0.9,1.4-1.5,3-1.9,3.6
                                                     c1.3,2,2.7,3.4,3.2,5.1c0.6,1.7-0.3,4.1,0.6,5.5c2,3.1,0.7,4.8-0.7,5.7c-0.3,2.6,0.1,5.2-0.8,5.7c-1.5,0.9-3.8,0.5-5.8,0.7
                                                     c2.2,0.8,3.4,1.5,1.8,3.9c-0.3,0.5,0,1.9,0.5,2.5c1.8,2,6.8,1.4,8.7-0.6c1-1,2.7-1.3,4.1-2c0.3,1.6,0.9,3.2,0.8,4.7
                                                     c-0.1,1.6-0.9,3.2-1.5,5.2c-2.5,0.3-4,2.3-3.5,5.4c0.3,1.8,1.3,3.4,1.8,5.1c0.9,3.3,1.7,6.6,2.6,9.9c-0.3,0.2-0.7,0.4-1,0.6
                                                     c1.7,0.5,3.3,1.1,4.9,1.6c1.4-3.6,2.7-6.7,1-10.6c-1.4-3.3,1.2-5,4.9-3.9c3.8,1.1,7.8,1.7,11.4,3.2c4.6,1.9,7.2-2.2,10.2-2.5
                                                     c2.3-4.9,0.9-10.4,4.8-14.5c1.6,4.6,2.5,9.4,8.8,9.8c-0.6-4.2-1.1-7.9-1.6-11.6c4.3,1.5,8,2.9,12.2,4.4c1.2,1.9,2.9,4.6,4.8,7.8
                                                     c1-3.6,1.8-6.7,2.7-9.9c-1.2-0.2-1.9-0.1-2.4-0.4c-1-0.6-1.8-1.4-2.7-2.1c0.9-0.7,1.7-1.9,2.7-2c2.4-0.3,2.2-1.8,2.2-3.5
                                                     c-0.2-5.1,0.4-5.9,5.3-6.9c-1.2-3.2-0.3-4.3,3.3-3.9c2.4,0.3,4.8,1,7.2,1.1c1.6,0.1,4.1-0.1,4.7-1.1c2-3.2,6.1-6,3.7-10.8
                                                     c-0.2-0.5-0.3-1.2-0.2-1.8c0.3-3.8,1.9-4.7,5.2-2.7c0.8,0.5,1.7,0.9,2.9,1.5c0.1-1,0.1-1.5,0.2-2c0.6-7.8,1-15.6,5.8-22.3
                                                     c1.6-2.2,0.5-11.2-1.7-12.3c-4.2-2.1-3-5.8-2.8-8.8c0.2-2.8,3.1-1.2,4.8-1.6c0.8-0.2,1.5-0.7,1.7-0.8c3.9,2.4,7.5,4.7,11.1,6.8
                                                     c0.7,0.4,1.9,0.4,2.8,0.1c2.2-0.8,4.2-1.8,6.5-2.9C-1059.1,1216.8-1059.5,1216.3-1061.8,1214.4z"
                                            ></path>
                                        </a>
                                        <a xlinkTitle="Sylhet" key={'Sylhet1' + item?.key}>
                                            <text id="XMLID_4_" transform="matrix(1 0 0 1 -1167.6318 1246.8512)" className="map1 map2 map3">{item.percentage}%</text>
                                        </a>
                                    </>
                                    : item.division_name === "Khulna" ?
                                        <>
                                            <a key={'Khulna' + item?.key}>
                                                <path
                                                    id="XMLID_19_"
                                                    className="map mapKhulna"
                                                    d="M-1329.6,1484.6c-2.7,0.3-3.9-0.8-3.6-3.8c0.1-0.6,0-1.1-0.1-1.5l-0.9-10
                                                     c-3.9-3.3-2.3-5.3,0.3-7.2c1.1-0.8,1.6-2.4,2.7-4.2c-3-0.4-5.4-0.3-7.7-1c-1.6-0.5-3-1.9-4-3.2c-0.3-0.4,0.8-2.1,1.6-2.9
                                                     c1.9-2,0.6-2.8-1.1-3.8c-1-0.5-1.9-1.3-2.5-2.1s-0.8-1.8-1.3-2.7c-1.6-2.3-3.2-4.6-4.9-6.9c-2.2-2.9-4.5-5.7-6.6-8.6
                                                     c-1.3-1.7-1.6-3.5,1.2-5c0.9,3.2,2.6,2,5.1,0.8c-2.4-1.3-5-1.9-6-3.5c-1.1-1.8-0.8-4.5-1.1-6.9c-0.8,0.1-1.2,0.1-1.7,0.1
                                                     c-4.1,0.6-4,0.5-2.9-3.5c0.4-1.3-0.3-3.2-1.1-4.5c-2.2-3.6-4.4-7-1.8-11.3c1.3-2.2-0.8-8.8-3.2-9.7c-2.7-1-5.6-1.4-8.9-2.1
                                                     c0-0.3,0.1-0.8,0.3-1c2.9-3.6,2.2-6.5-1.3-9.2c-0.6-0.5-0.2-2.2-0.2-2.7c-1.7-1.5-3-3-4.6-4.1c-0.9-0.7-2.5-1.4-3.4-1
                                                     c-2.8,1-4.8,0.2-6.4-2c-1.4-1.9-2.7-3.7-4-5.7c-0.3-0.5-0.5-1.4-0.3-2c1.7-4.4,3.7-8.8,5.3-13.3c0.6-1.8,1.2-4.9,0.2-5.7
                                                     c-2.9-2.7-5-6.9-9.8-6.6c-0.9,1.3-1.6,2.9-2.7,3.8c-0.7,0.6-3,0.8-3.1,0.6c-1-2.8-3.4-2.1-5.3-2.5s-3.9-0.6-5.5-1.5
                                                     c-1.9-1-4-2.4-5.1-4.2c-1.4-2.2-2.1-5-2.6-7.6c-0.8-4.1-2.7-6.7-7.1-6.4c-1.9,0.1-3.8,0.6-5.6,1c-1,0.2-2.3,1.2-3,0.9
                                                     c-5.5-2.3-11.9-3.3-14.8-9.5c-0.2-0.4-0.7-0.6-1.7-1.6c-2.5,9.8,3.1,17,5.9,24.7c-1.2,0.5-2.1,0.9-2.3,0.9
                                                     c-2,10.1-8.8,12.6-17.6,13.7c2.3,3.1,2.3,6-0.8,8.4c-0.1,0,0,0.2,0,0.4c0,3.9-0.3,7.8,0.1,11.6c0.3,2.6,1.5,5,2.3,7.4
                                                     c1.8,0,3.2,0,3.6,0c2.4,3.7,3.9,7.5,6.7,9.5c2.2,1.6,4.1,5.8,8.4,3.5c0.6-0.3,3.1,1.2,3,1.4c-0.7,2.3-1.6,4.5-2.6,6.7
                                                     c-0.3,0.8-1.2,1.4-1.3,2.2c-0.3,2-0.1,4.2-0.6,6.2c-0.4,1.8-1.4,3.4-2.2,5.1c0.2,0.1,0.5,0.2,0.7,0.3c-1.2,0.7-2.4,1.5-4.6,2.8
                                                     c3.4,2.5,6.2,4.6,9.1,6.8c1.2-4,1.7-4.1,4.1-3.1c4,1.6,8,3,12.1,4.3c0.6,0.2,1.7-1.1,2.5-1.1c1.1,0.1,2.1,0.8,3.2,1.2
                                                     c-0.9,1.4-1.7,3-2.8,4.3c-0.7,0.8-2.1,1.1-2.8,2c-2.2,2.5-5,4.8-6.2,7.7c-1.2,3-0.6,6.8-0.8,10.2c0,0.5-0.3,0.9-0.3,1.4
                                                     c0,1-0.2,2.5,0.3,2.8c3.2,2.1,4.3,5.1,4.1,8.8c0,0.3,0.9,1.1,1.2,1c3.9-1.1,4.2,2.6,4.5,4.3c0.4,2-0.5,4.9-1.9,6.5
                                                     c-3.4,3.8-3.9,4.6-0.6,8.2c2,2.2,2,4,0.8,6.2c-0.4,0.8-0.9,1.6-1.4,2.5c0.4,0,0.8,0,1.2,0.1c0.1,1.5,0.9,3.5,0.2,4.5
                                                     c-1.3,2-0.6,3,0.4,4.8c2.2,3.9,4.7,7.8,4.6,12.5c0,1.8-0.9,3.7-0.7,5.4c0.5,3.9-0.5,8.5,3.4,11.4c0.1,0.1,0.1,0.2,0.1,0.3
                                                     c-1.2,3.8,2.4,5.6,3.4,8.5c0.7,1.9,1.8,3.6,3,5.9l-3.5,14.9l6.6,10.6l9.4,9.6l8.5,15.3l-0.4,8.5l8.5,5.3l19.4-7.7l13.4-6l15.6-10.8
                                                     l5.7-13.1l-0.8-19.7l-3.4-5.7l-9-10.8l12.8-11.6l2.1-10c0.8-0.6,1.4-1.1,1.4-1.1c-1.2-1.5-2.7-2.7-3-4.2c-0.5-2.3,1.6-2.7,3.4-3.4
                                                     C-1330.2,1492-1328.7,1488.1-1329.6,1484.6z M-1378.2,1381C-1378.1,1381.2-1378.2,1381.1-1378.2,1381L-1378.2,1381z"
                                                ></path>
                                            </a>
                                            <a xlinkTitle="Khulna" key={'Khulna1' + item?.key}>
                                                <text id="XMLID_5_" transform="matrix(1 0 0 1 -1422.4619 1431.8199)" className="map1 map2 map3">{item.percentage}%</text>
                                            </a>
                                        </>
                                        : item.division_name === "Mymensingh" ?
                                            <>
                                                <a key={'Mymensingh' + item?.key}>
                                                    <path
                                                        id="XMLID_61_"
                                                        className="map mapMymensingh"
                                                        d="M-1357,1209.6c0.4,2.7,1.3,5.6,0.8,8.2c-1.2,5.8-0.1,8.5,5.2,11c0.7,0.3,1,1.6,1.8,3.1
                                                     c0.4,0.1,2.8,0.2,3,0.9c1.1,4.1,1.6,8.3,2.3,12.4c0,0.2-0.4,0.3-0.4,0.6c-0.6,2.6-2,5.4-1.5,7.8c0.4,2,0.2,3.5-0.6,4.9l13.8-12
                                                     l3.1-6l17.3,20v17l10.1,16.7l16.1,5.8l7.9,6.1l5.2-6.2h16.2l-3.1-9.9l1.2-8.1l17.4,2.4v-10.7h20.2l5.7-7.1l15.3-16.8l0.8,0.2
                                                     c0.2-0.1,0.3-0.2,0.4-0.2c3.4-0.1,4.9-3,4.9-5.2c0-2.4-2.9-3.1-5.4-2.9c-1.1,0.1-2.3-0.5-3.6-0.8c0.3-2.7,0.5-5.1,0.7-7.6
                                                     c-2-0.6-3.7-2.1-3.4-5.5c0.1-1.5-0.5-3.1-1-4.6c-1.1-3.3-3.5-3.9-6.4-1.8c-0.5,0.4-1.5,0.8-1.9,0.6c-3.3-2-6.5-4.1-10.2-6.5
                                                     c1.8-1.3,2.7-2,2.9-2.2c-0.8-2.3-1.5-4.2-1.9-6.1c-0.5-2.1-0.9-4.3-1.2-6.5c-0.4-3,1-4.2,3.6-3.4c-0.7-2.6-1.4-5.1-2.2-8.2
                                                     c-2,0-4.2,0.1-6.4,0c-2-0.1-4.1-0.1-6.1-0.6c-1.9-0.4-3.3-3-5.7-0.4c-1.3,1.4-4.1,1.4-5.5,2.8c-5.7,5.6-9.7,5.6-14.7-0.3
                                                     c-3.6-4.3-4.3-4.3-9.6-0.5l-8.2-0.2c-2.9-1.5-5.3-3.5-8-4.1c-7.3-1.7-14.7-2.8-22.1-4.2c-0.8-0.2-1.6-0.6-2.3-1
                                                     c-4.7-2.4-9.3-5-14.2-7.1c-2-0.8-4.5-0.8-6.7-0.6c-2.5,0.1-5,0.8-7.4,1.3c-1.1-5.7-2.1-10.5-3.1-15.8c-1.2,1.9-1.8,3.6-2.9,4.3
                                                     c-4.4,2.8-4.4,2.7-2.6,7.6c0.2,0.7,0.6,1.3,0.9,2c1.6,4.4,0,7.5-4.3,9.2c-1.3,0.5-2.6,2-3.1,3.3c-1.2,3.1-2.2,6.3-2.8,9.6
                                                     c-0.4,2.3,0,4.7,0.3,7C-1358,1204.2-1357.4,1206.9-1357,1209.6z"
                                                    ></path>
                                                </a>
                                                <a xlinkTitle="Mymensingh" key={'Mymensingh1' + item?.key}>
                                                    <text id="XMLID_62_" transform="matrix(1 0 0 1 -1332.0957 1229.6251)" className="map1 map2 map3">{item.percentage}%</text>
                                                </a>
                                            </> : item.division_name === "Barishal" ?
                                                <>
                                                    <a key={'Barishal' + item?.key}>
                                                        <path
                                                            id="XMLID_21_"
                                                            className="map mapBarishal"
                                                            d="M-1198.6,1519.1c-0.9-2.5-2-5-3.4-7.3c-1.5-2.5-2.2-6.1-6.4-5.9c0,0.1,0,0.1,0,0.2
                                                     c-0.5-0.6-1-1.2-1.5-1.8c-2.6-4.3-6.6-8-11.7-10.7c0.3-0.2,0.6-0.5,0.8-0.8c-1.3-0.5-2.4-0.9-3.3-1.2c-0.4-6.1-6.3-2.3-8.4-5.1
                                                     c0.2,0.4,0.5,0.8,0.7,1.2c-0.5-0.2-1.1-0.5-1.6-0.7c-0.5-0.3-1-0.6-1.4-0.9c-1.5-1-3.1-1.9-4.3-3.3c-1.9-2.2-2.7-5.2-4.3-7.7
                                                     c-2.2-3.4-6-5.7-8.3-8.9c-0.5-1.2-1-2.3-1.6-3.4c-0.6-2.1-0.7-4.3-1.9-6.2c-1.2-1.9-3.3-3.1-5-4.5c-1.6-1.3-2.9-2.9-3.8-4.7
                                                     c1-0.6,2.4-2,2.3-2.9c-0.4-3.1-1.4-6.2-2.2-9.6c-4.1,0.7-7.5,1.3-11,1.9c0,0.4-0.1,0.7-0.1,1.1c1.7,0.9,3.4,1.8,4.8,3
                                                     c-0.2,0.1-0.4,0.2-0.6,0.4c-2.9-0.1-5.8-1.4-7.8-3.6c-0.6-0.7-1.2-1.6-2-2c-0.8-0.4-2.1-0.4-2.6,0.5l-0.3,0.7
                                                     c-0.4-0.2-0.8-0.4-1.2-0.6c2-1.1,3.1-1.8,5.1-2.9c-3.5-0.3-6.2-0.5-8.8-0.8c-0.3,0.9-0.5,1.2-0.5,1.6c0.6,7.3,0.6,7.3-6.4,8.2
                                                     c-3.8,0.5-5.2-0.8-5.1-4.8c0.1-2,0-4.1,0-6.3c-1.9,0.5-3.4,0.8-4.5,1.1l0,0l0,0c-3.1,0.9-3.3,1.5-3.7,3.9l-1.4,1.8l0,0
                                                     c-1.2-0.7-2.5,0.6-6,4.4c0,0-3.1,6.3-4.6,9.1s-7.1,4.2-7.1,4.2l-1.9,4.2l-0.8,4.4c0.2,3.4,0.8,6.5,0.6,9.6c-0.1,2.7-1,5.3-1.6,8.1
                                                     c3,0.6,4.9,1.6,4,4.5c-0.6,2-1.3,4-1.9,6.3c2.7,0.6,5.5,1.3,8,1.8c-1.5,2.3-2.6,3.9-3.7,5.5c-1.1,1.7-2.2,3.4-3.4,4.9
                                                     c-5.5,6.5-9.9,13.3-6.7,22.3c1.8,5.1,3.6,10,2.2,15.7c-0.4,1.9,0.9,4.2,1.5,6.3c0.2,0.7,0.5,1.4,0.7,2c0.2,1.3,0.5,2.6,0.9,3.7
                                                     c0.1,0.4,0.2,0.7,0.3,1c1.8,4.6,4.7,7.7,8,7.7c0.7,0,1.3-0.1,2-0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.4,0.8,0.8,1.5,1.1,2.2
                                                     c0.1-0.1,0.2-0.1,0.4-0.2c1.5,2,3.1,4.1,5.3,5.1c0.1,0.1,0.2,0.2,0.2,0.3c3,2.8,10.1,3,12.7-0.3c0.4-0.5,0.8-1.1,1.2-1.8
                                                     c0.9-0.3,1.9-0.7,2.8-1c1.7-0.6,3.5-1.2,5.2-1.8c0.2,0.5,0.4,1,0.7,1.2c0.7,0.3,2,0.3,2.7-0.1c0.6-0.4,0.8-1.4,0.9-2.2
                                                     c0.7-0.1,1.4-0.3,2.1-0.4c0,0,0,0,0,0.1c0,0,0.1,0,0.1-0.1c0.3,0,0.6-0.1,0.9-0.1c3.3-0.4,6.7-0.2,10-0.4c2-0.2,4-0.5,5.9-1
                                                     c0.3,0.2,0.6,0.2,0.7,0.2c0.7-0.1,1.6-0.5,2.2-1.1c1.3-0.4,2.6-0.9,3.9-1.5c0.5,0,0.9,0,1.4,0s0.9,0,1.4,0c0.3,0,0.7,0,1,0
                                                     c-0.1,0.8-0.1,1.6,0,2.5c0.7-0.8,1.3-1.7,2-2.6c2.8-0.1,5.6-0.6,8-2c2.1-1.2,3.7-3,5.7-4.4c2-1.3,4.2-2.2,6.4-3.1
                                                     c5.1-2.1,10.3-4.1,15.4-6.2c-0.2,0.7-0.3,1.5-0.5,2.2c0.9-0.4,2.3-0.5,2.5-1.2c0.3-0.6,0.2-1.2,0.2-1.9c2.7-1.1,5.3-2.3,7.4-4.3
                                                     c0.7-0.6,1.2-1.4,1.8-2.1c0.3-0.2,0.7-0.4,1-0.7c4.7-3.4,6.9-8.3,8.1-13.7C-1197.5,1522.5-1198,1520.6-1198.6,1519.1z
                                                      M-1321.9,1528.8c-0.1,0-0.2,0-0.3,0c0.1-0.2,0.3-0.4,0.5-0.6C-1321.8,1528.4-1321.9,1528.6-1321.9,1528.8z"
                                                        ></path>
                                                    </a>
                                                    <a xlinkTitle="Barishal" key={'Barishal1' + item?.key}>
                                                        <text id="XMLID_60_" transform="matrix(1 0 0 1 -1308.7625 1514.9181)" className="map1 map2 map3">{item.percentage}%</text>
                                                    </a>
                                                </>
                                                : <></>
            ))}
        </svg >
        </>
    )
} 